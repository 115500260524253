import Vue3DModelReader from "../3dvue/utils/Vue3DModelReader";
import Vue3DProductGenerator from "../3dvue/utils/Vue3DProductGenerator";

$body.on("click","[api-click]",function(e){

    let $clicked=$(this);
    let $modal = $('#processModal');
    let $title = $('#process-modal-title');
    let $info = $('#process-modal-info');
    let $button = $('#process-modal-close');

    let action=$(this).attr("api-click");
    let mesh = null;
    let material = null;

    //e.preventDefault();
    e.stopPropagation();

    switch (action) {

        case "3dvue/update_bdd":
            $modal.modal('show');
            $title.html("Mise à jour de la base de données");
            $info.html("");
            $info.append("Lecture des fichiers 3D...<br><br>");

            api.Api3dvue.updateModelDB( (msg, html, data)=> {
                updateModelDBDisplay(msg, data, $info, ()=>{

                    let dataToAnalyse = data["created"].concat(data["updated"]);
                    analyseModel(dataToAnalyse, $info, ()=>{

                        api.Api3dvue.updateMaterialDB( (msg, html, data)=>{
                            updateMaterialDBDisplay(msg, data, $info, ()=>
                            {
                                $button.removeAttr("disabled");
                                $button.attr("api-click","3dvue/reload_page");
                            });
                        });
                    });
                });
            });

            break;

        case "3dvue/reload_page":
            location.reload();
            break;

        case "3dvue/update_mesh_material":

            let model = $clicked.attr("model-name");
            mesh = $clicked.attr("mesh-name");
            material = $clicked.attr("material-name");
            let value = $(this).prop('checked');

            $(".update-product-db").addClass("blink");

            if(model && mesh && material)
            {
                api.Api3dvue.getModelByName(model, (msg, html, data)=>{
                    let meshObj = JSON.parse(data.meshMaterialList);
                    if(value) meshObj[mesh].push(material);
                    else {
                        let index = meshObj[mesh].indexOf(material);
                        if(index > -1) meshObj[mesh].splice(index, 1);
                    }

                    let dataUpdate = {
                        name:data.name,
                        mesh_material_list: JSON.stringify(meshObj)
                    }

                    api.Api3dvue.updateModel(dataUpdate, null, null);
                }, null);
            }

            break;


        case "3dvue/change_material":

            mesh = $clicked.attr("mesh-name");
            material = $clicked.attr("material-name");

            let materials = {}
            materials[mesh] = material;

            window.Site.loadMaterials(materials);

            break;

        case "3dvue/download-product":

            //$modal.modal('show');
            //$info.html("Nous préparons votre fichier, merci de patienter.");

            Vue3DProductGenerator.download(window.Site.vue3d.model);

            break;

        case "3dvue/start-ar":

            let mobile = false;
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                mobile = true;
            }

            $modal.modal('show');

            $info.html("");

            if(!mobile)
            {
                let $message = $("<div>Désolé, la réalité augmentée n'est pas supportée sur cet appareil.</div><br>")
                $info.append($message);

                let $button_open = $('<button type="button" id="process-modal-close" class="btn btn-primary btn-sm">Fermer</button>');
                $info.append($button_open);

                $button_open.on("click", ()=>{
                    $modal.modal('hide');

                    $info.html("");
                });

                return;
            }

            let $message = $("<div>Nous préparons votre expérience de réalité augmentée, merci de patienter.</div><br>")
            $info.append($message);

            let $button_open = $('<button type="button" id="process-modal-close" class="btn btn-primary btn-sm" disabled>Ouvrir</button>');
            $info.append($button_open);

            function arReady(url)
            {
                window.Site.vue3d.stopRenderer();

                let $viewer = $('<model-viewer id="ar-viewer" style="height: 0; width: 0;" alt="RA" src="'+url+'" ar ar-scale="fixed" camera-controls shadow-intensity="1" ar-modes="scene-viewer webxr quick-look"></model-viewer>')
                $info.append($viewer);

                $viewer.on("load", ()=>{

                    $message.html("Votre expérience est maintenant prête");
                    $button_open.removeAttr("disabled");
                    $button_open.on("click", ()=>{

                        $message.html("Vous pouvez retourner à la navigation");

                        $button_open.html("Fermer");
                        $button_open.off("click");

                        $button_open.on("click", ()=>{
                            $modal.modal('hide');

                            $info.html("");
                            window.Site.vue3d.startRenderer();
                        });

                        const viewer = document.querySelector('#ar-viewer');
                        if (viewer.canActivateAR) {
                            viewer.activateAR();
                        } else {
                            $message.html("Désolé, la réalité augmentée n'est pas supportée sur cet appareil.");
                        }
                    });
                });


            }


            //todo ajouter une fonction qui regarde si le fichier existe déjà, si le fichier est plus récent que la source, si le fichier est plus récent que chacun de ces materiaux
            let productGenerator = new Vue3DProductGenerator(window.Site.vue3d.model);
            productGenerator.on(Vue3DProductGenerator.EVENT_COMPLETE, (url)=>{
                arReady(url);
            });

            productGenerator.on(Vue3DProductGenerator.EVENT_PROGRESS, (info)=>{
                console.log(info);
            });


            /*
            api.Api3dvue.getProductGLFT(LayoutVars.SKU, (html, data, json)=>{
                if(json.path === false)
                {
                    let productGenerator = new Vue3DProductGenerator(window.Site.vue3d.model, LayoutVars.SKU);
                    productGenerator.on(Vue3DProductGenerator.EVENT_COMPLETE, (url)=>{
                        arReady(url);
                    });

                    productGenerator.on(Vue3DProductGenerator.EVENT_PROGRESS, (info)=>{
                        console.log(info);
                    });
                } else {
                    let url = json.path;
                    arReady(url);
                }
            });
             */

            break;

        case "3dvue/screenshot":

            let base64 = window.Site.vue3d.getImageFromRenderer();

            let a = document.createElement("a"); //Create <a>
            let timestamp = +new Date;
            a.href = base64; //Image Base64 Goes here
            a.download = "3dvue-"+timestamp+".png"; //File name Here
            a.click();

            break;

        default:
            alert(`api-click Action ${action} non prise en charge`);
    }
});


$body.on("submit","[api-submit]",function(e){
    let $form=$(this);
    let action=$(this).attr("api-submit");
    e.preventDefault();
    e.stopPropagation();

    switch (action) {
        case "todo":
        default:
            alert(`Action ${action} non prise en charge par api-submit`);

    }
});


function updateModelDBDisplay(msg, data, $info, cbSuccess)
{
    $info.append(msg+"<br><br>");
    if (data["created"].length > 0) {
        $info.append(data["created"].length + " nouveaux fichiers 3D<br>");
        for(let i = 0; i<data["created"].length; i++)
        {
            $info.append("&nbsp;&nbsp;"+data["created"][i].name + "<br>");
        }
        $info.append("<br>");
    }

    if (data["updated"].length > 0) {
        $info.append(data["updated"].length + " fichiers 3D mis à jour<br>");
        for(let i = 0; i<data["updated"].length; i++)
        {
            $info.append("&nbsp;&nbsp;"+data["updated"][i].name + "<br>");
        }
        $info.append("<br>");
    }

    if (data["deleted"].length > 0) {
        $info.append(data["deleted"].length + " fichiers 3D supprimés<br>");
        for(let i = 0; i<data["deleted"].length; i++)
        {
            $info.append("&nbsp;&nbsp;"+data["deleted"][i].name + "<br>");
        }
        $info.append("<br>");
    }

    if(cbSuccess) cbSuccess();
}


function updateMaterialDBDisplay(msg, data, $info, cbSuccess)
{
    $info.append(msg+"<br><br>");
    if (data["created"].length > 0) {
        $info.append(data["created"].length + " nouveaux materiaux<br>");
        for(let i = 0; i<data["created"].length; i++)
        {
            $info.append("&nbsp;&nbsp;"+data["created"][i].name + "<br>");
        }
        $info.append("<br>");
    }

    if (data["updated"].length > 0) {
        $info.append(data["updated"].length + " materiaux mis à jour<br>");
        for(let i = 0; i<data["updated"].length; i++)
        {
            $info.append("&nbsp;&nbsp;"+data["updated"][i].name + "<br>");
        }
        $info.append("<br>");
    }

    if (data["deleted"].length > 0) {
        $info.append(data["deleted"].length + " materiaux supprimés<br>");
        for(let i = 0; i<data["deleted"].length; i++)
        {
            $info.append("&nbsp;&nbsp;"+data["deleted"][i].name + "<br>");
        }
        $info.append("<br>");
    }

    if(cbSuccess) cbSuccess();
}

function analyseModel(dataToAnalyse, $info, cbSuccess)
{
    if(dataToAnalyse <= 0) {
        if(cbSuccess) cbSuccess();
        return
    }

    $info.append("Analyse des nouveaux fichiers 3D...<br><br>");

    let $progress = $("<div></div>")
    $info.append($progress);

    let modelReader = new Vue3DModelReader(dataToAnalyse);
    modelReader.on(Vue3DModelReader.EVENT_READ_COMPLETE, ()=>{

        $progress.html("Analyse des modèles terminée<br><br>");
        if(cbSuccess) cbSuccess();
    });

    modelReader.on(Vue3DModelReader.READ_PROGRESS, (info)=>{
        $progress.html("Analyse du modèle : "+info.progress+"<br>");
    });
}