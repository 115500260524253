import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {GLTFExporter} from "three/examples/jsm/exporters/GLTFExporter";
var EventEmitter = require('events').EventEmitter;
import * as THREE from 'three';

export default class Vue3DGltfGenerator extends EventEmitter
{

    static EVENT_PROGRESS = "EVENT_PROGRESS";
    static EVENT_COMPLETE = "EVENT_COMPLETE";

    constructor(originalFile) {
        super();

        let me = this;

        let root = JSON.parse(LayoutVars.envSettings).root_path;

        const loader = new GLTFLoader();
        loader.load(root+"/"+originalFile,
            (glb) => {

                let data = glb.scene;

                const geometry = new THREE.BoxGeometry( 0.001, 0.001, 0.001 );
                const material = new THREE.MeshBasicMaterial( { color: 0x000000, transparent:true, alpha:0 } );
                const mesh = new THREE.Mesh( geometry, material );
                data.add(mesh);

                const exporter = new GLTFExporter();
                exporter.parse(
                    data,
                    // called when the gltf has been generated
                    function ( gltf ) {
                        let filename = originalFile.replace(/^.*[\\\/]/, '');
                        filename = filename.replace(/\.[^/.]+$/,'')+".gltf";

                        let output = JSON.stringify(gltf);
                        let blob = new Blob([output], { type: 'application/json' });
                        let target_file = "3d/exports/"+filename;
                        me.uploadBlobInChunks(blob, filename, (url)=>{
                            me.emit(Vue3DGltfGenerator.EVENT_COMPLETE, target_file);
                        });
                    },
                    null,
                    {
                        maxTextureSize:1024
                    }
                );

            },
            (xhr) => {
                //me.onLoadProgress(me, "loading model", Math.round(xhr.loaded / xhr.total * 100));
            },
            (error) => {
                console.log(error);
            }
        )

    }

    async uploadBlobInChunks(blob, fileName, cbSuccess)
    {
        let me = this;

        const CHUNK_SIZE = 1024 * 1024; // 1 MB per chunk
        const totalChunks = Math.ceil(blob.size / CHUNK_SIZE);

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const start = chunkIndex * CHUNK_SIZE;
            const end = Math.min(blob.size, start + CHUNK_SIZE);
            const chunk = blob.slice(start, end); // Slice the blob into chunks

            const formData = new FormData();
            formData.append("file", chunk);
            formData.append("chunkIndex", chunkIndex);
            formData.append("totalChunks", totalChunks);
            formData.append("fileName", fileName);

            await me.uploadChunk(formData, cbSuccess);
        }
    }

    async uploadChunk(formData, cbComplete) {
        let url=`${LayoutVars.rootUrl}/api/3dvue/upload-file`;

        try {
            const response = await fetch(url, {
                method: "POST",
                body: formData,
            });
            const result = await response.text();
            let data = JSON.parse(result);

            if(data.json.upload_complete)
            {
                if(cbComplete) cbComplete(data.json.upload_complete);
            }


        } catch (error) {
            console.error("Error uploading chunk:", error);
        }
    }
}