import Api from "./api/Api";
import Api3dvue from "./api/Api3dvue";

import Vue3DIframeWrapper from "./3dvue/wrapper/Vue3DIframeWrapper";
import Vue3DConfiguratorWrapper from "./3dvue/wrapper/Vue3DConfiguratorWrapper";
import Vue3DThumbnailGeneratorWrapper from "./3dvue/wrapper/Vue3DThumbnailGeneratorWrapper";

export default class Site {
    constructor() {
        /**
         *
         * @type {Site}
         */
        let me = this;
        me._initListeners();
        //---------------------go------------------------------------------
        me.resizeStage();
        me.onDomChange();
        Site.navActive();

        //api
        require("./api/api-click.js");
        window.api=new Api();
        window.api.Api3dvue=new Api3dvue();

        me.init3dVue();
    }

    /**
     *
     * @private
     */
    _initListeners() {

        let me=this;

        //require("./layout/NavMenu");
        //NavMenu.__init();

        //require("./components/data-zoom-img");
        //require("./components/data-is-lang");
        //require("./organisms/data-cards-container.js");


        //require("./blocks/FormContact");
        //FormContact.initFromDom();

        //ferme le menu quand on change d'url
        $body.on(EVENTS.HISTORY_CHANGE_URL,function(){
            $body.attr("data-page-transition-state","start");
            //stope en attendant que la transition soit finie
            PovHistory.readyToinject=false;
            //dit qu'on est prêt à afficher la page (s'assure qu'on reste au moins une seconde sur l'écran de transition)
            setTimeout(function(){
                PovHistory.readyToinject=true;
            },500);
            NavMenu.close();
            $(".lil-gui").remove();
        });

        //changement d'url et HTML injecté
        $body.on(EVENTS.HISTORY_CHANGE_URL_LOADED_INJECTED,function(){
            $body.attr("data-page-transition-state","end");
            me.onDomChange();
            //scroll top
            $(window).scrollTop(0);
            Site.navActive();
            me.init3dVue();

            if(typeof gtag !== 'undefined' && LayoutVars.googleAnalyticsId){
                //hit google analytics
                gtag('config', LayoutVars.googleAnalyticsId, {'page_path': location.pathname});
            }

        });

        STAGE.on(EVENTS.RESIZE,function(){
            me.resizeStage();
        });
        $body.on(Pov.events.DOM_CHANGE,function(){
            me.onDomChange();
        });
    }

    /**
     * Selectionne / déselectionne l'item de nav de la page en cours
     */
    static navActive(){
        $("[data-href-uid]").removeClass("active");
        $("[data-href-uid='"+PovHistory.currentPageInfo.uid+"']").addClass("active");
    }

    /**
     * Adapte des élements à l'écran
     */
    resizeStage(){
        //ou pas :)
    }

    /**
     * Initialisations d'objets dom
     */
    onDomChange(){
        //Slick.initFromDom();
        //ou pas :)
    }


    init3dVue()
    {
        let me = this;
        let $ModelViewer = $("#ModelViewer");

        if($ModelViewer.length > 0)
        {
            if($ModelViewer.hasClass('init')) return;
            $ModelViewer.addClass('init');

            if($ModelViewer.attr("mode") === "player")
            {
                Vue3DIframeWrapper.init(me,"#ModelViewer");
            } else if($ModelViewer.attr("mode") === "configurator") {
                Vue3DConfiguratorWrapper.init(me, "#ModelViewer");
            }
        }
    }

    loadModel(name, options = null)
    {
        let me = this;

        if(options && options.materials)
        {
            api.Api3dvue.getMaterialList(options.materials, (msg, html, json)=>{
                options.materials = json;
                api.Api3dvue.getModelByName(name, (msg, html, json)=>{
                    if(me.vue3d){
                        me.vue3d.loadModel(json.path, options);
                    }
                }, ()=>{
                    //todo display error model n'existe pas
                });

            }, ()=>{
                //todo display error material n'existe pas
            });
        } else {
            api.Api3dvue.getModelByName(name, (msg, html, json)=>{
                if(me.vue3d){
                    me.vue3d.loadModel(json.path, options);
                }
            }, ()=>{
                //todo display error model n'existe pas
            });
        }
    }

    loadMaterials(materials)
    {
        let me = this;
        api.Api3dvue.getMaterialList(materials, (msg, html, json)=> {

            let materialToSet = json;

            let opt = JSON.parse(LayoutVars.options);
            Object.keys(materials).forEach(function(k){
                if(opt.materials[k]) opt.materials[k] = materials[k];
            });

            LayoutVars.options = JSON.stringify(opt);

            let data = {};
            data.model = LayoutVars.model;
            data.material_list = JSON.parse(LayoutVars.options).materials;
            me.vue3d.setMaterials(materialToSet);

            //mise à jour de l'id du produit
            api.Api3dvue.getProductURL(JSON.stringify(data), (msg, html, json)=>{
                LayoutVars.productId = json.url;
                if($(".debug-product-id").length > 0)
                {
                    let base_url = location.protocol + '//' + location.host + LayoutVars.fmkHttpRoot + '/?p='+json.url;
                    $(".debug-product-id .content").html(base_url);
                }


            }, (e)=>{
                console.log("erreur");
            });

        }, ()=>{
            //todo display error model n'existe pas
        });
    }
}