import Vue3D from "./../Vue3D";

export default class Vue3DIframeWrapper
{

    static EVENT = {
        READY:"READY",
        LOAD_COMPLETE:"LOAD_COMPLETE"
    }

    static ACTION = {
        LOAD_MODEL:"LOAD_MODEL",
        CHANGE_MATERIAL:"CHANGE_MATERIAL",
        START_RENDERER:"START_RENDERER",
        PAUSE_RENDERER:"PAUSE_RENDERER",
        RESET_CAMERA:"RESET_CAMERA"
    }

    static model_nav = {}

    static init(site, container)
    {
        site.vue3d = new Vue3D(container, LayoutVars.rootPath);
        site.vue3d.initScene();

        site.vue3d.on(Vue3D.READY, ()=>{
            parent.postMessage({event:Vue3DIframeWrapper.EVENT.READY}, "*");
        });

        site.vue3d.on(Vue3D.LOAD_COMPLETE, ()=>{
            //todo renvoyer la navigation dans le model et la config en cours
            parent.postMessage({event:Vue3DIframeWrapper.EVENT.LOAD_COMPLETE, data:{}}, "*");
        });

        if(LayoutVars.model)
        {
            site.loadModel(LayoutVars.model, LayoutVars.options?JSON.parse(LayoutVars.options):null);
        }

        window.addEventListener("message", function(e) {
            switch (e.data.action)
            {
                //case Vue3DIframeWrapper.ACTION.LOAD_MODEL:
                //    site.loadModel(e.data.modelName, (e.data.options)?e.data.options:null);
                //    break;
                case Vue3DIframeWrapper.ACTION.CHANGE_MATERIAL:
                    site.loadMaterials(e.data.materials);
                    break;
                case Vue3DIframeWrapper.ACTION.START_RENDERER:
                    site.vue3d.startRenderer();
                    break;
                case Vue3DIframeWrapper.ACTION.PAUSE_RENDERER:
                    site.vue3d.stopRenderer();
                    break;
                case Vue3DIframeWrapper.ACTION.RESET_CAMERA:
                    site.vue3d.resetCamera();
                    break;

            }
        });
    }
}