export default class Vue3DConfiguratorModel {

    vue3d = null;
    folder = null;
    urlCtrl = null;

    api = {
        model:"",
        url:""
    }


    constructor(_configurator)
    {
        let me = this;
        me.vue3d = _configurator.vue3d;

        me.folder = _configurator.gui.addFolder( 'Model');
        //me.folder.close();

        this.urlCtrl = me.folder.add(me.api, 'url');

        me.folder.add( me.api, 'model').options( LayoutVars.models.map(a => a.name) ).onChange(function (selection) {

            _configurator.materialConfigurator.destroy();

            const modelInfo = LayoutVars.models.find(({ name }) => name === selection);
            me.vue3d.loadModel(modelInfo.path, null, ()=>{
                _configurator.materialConfigurator.listMesh();
                me.updateURLFromScene();
            });
        });
    }

    updateURLFromScene()
    {
        let me = this;

        let mesh = [];
        me.vue3d.model.traverse(function (child) {
            if (child.isMesh) {
                let pattern = "mesh_";
                if(!child.name.includes(pattern)) return;
                let identifier = pattern+child.name.slice(pattern.length, pattern.length+3);

                if(mesh.some(obj => obj["id"] === identifier)) return;
                mesh.push({id:identifier, mesh:child});
            }
        });

        let materialList = {};
        mesh.forEach((el)=>{
            if(el.mesh.material.name !== "")
            {
                materialList[el.id] = el.mesh.material.name;
            }
        });

        const sortedEntries = Object.entries(materialList).sort(([keyA], [keyB]) => {
            return keyA.localeCompare(keyB);  // Sort keys alphabetically
        });

        const sortedMaterialList = Object.fromEntries(sortedEntries);

        let data = {
            model:me.api.model,
            material_list:sortedMaterialList
        }

        api.Api3dvue.getProductURL(JSON.stringify(data), (msg, html, json)=>{
            me.urlCtrl.setValue(me.vue3d.envSettings.root_path+"?p="+json.url);
        }, (e)=>{
            me.urlCtrl.setValue("Produit non existant");
        });
    }

}