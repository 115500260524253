import * as THREE from 'three';

import Vue3D from "../Vue3D";

export default class Vue3DMaterialManager {

    constructor() {}

    static setMaterials(_vue3d, materialList)
    {
        _vue3d.domContainer.classList.add("loading");

        let materialLoaded = 0;

        for (const [key, value] of Object.entries(materialList)) {

            let mapLoaded = 0;

            let mat = Vue3DMaterialManager.setMaterial(_vue3d, value, ()=>{

                mapLoaded++;

                if(mapLoaded === 6) {
                    materialLoaded++;

                    _vue3d.model.traverse(function (child) {

                        if (child.isMesh && child.name.includes(key)) {
                            if(value.name === "hidden") {
                                child.material = new THREE.MeshStandardMaterial( {color: 0xffffff} );
                                child.material.name = "hidden";
                                child.visible = false;
                            }
                            else {
                                child.material = mat;
                                child.visible = true;
                            }
                            _vue3d.aoShadow.render();
                        }
                    });

                    if(materialLoaded === Object.entries(materialList).length) {
                        _vue3d.domContainer.classList.remove("loading");
                        _vue3d.emit(Vue3D.LOAD_COMPLETE);
                        if(_vue3d.configurator) _vue3d.configurator.modelConfigurator.updateURLFromScene();
                    }

                }
            });
        }
    }


    static setMaterial(_vue3d, materialInfo, cbMapLoaded)
    {
        let me = this;

        let map_list = [];
        map_list[0] = materialInfo.albedoMap;
        map_list[1] = materialInfo.metallicMap;
        map_list[2] = materialInfo.normalMap;
        map_list[3] = materialInfo.roughnessMap;
        map_list[4] = materialInfo.alphaMap;
        map_list[5] = materialInfo.emissiveMap;

        let textures_loader = [];

        map_list.forEach(function(map)
        {
            if(map)
            {
                let loader = new THREE.TextureLoader().load( _vue3d.httpRootPath+"/"+map, cbMapLoaded, null, cbMapLoaded);
                //loader.colorSpace = THREE.SRGBColorSpace;
                loader.wrapS = THREE.RepeatWrapping;
                loader.wrapT = THREE.RepeatWrapping;
                if(materialInfo.repeat) loader.repeat.set(materialInfo.repeat, materialInfo.repeat);
                if(_vue3d.model.userData.extention === "glb") loader.flipY = false;
                textures_loader.push(loader);
            } else {
                textures_loader.push(null);
                cbMapLoaded();
            }
        });

        let mat = new THREE.MeshPhysicalMaterial( {
            name:materialInfo.name,
            color: 0xffffff,
            //envMap:_vue3d.scene.environment,
            //opacity:(materialInfo.opacity)?materialInfo.opacity:1,
            map: textures_loader[0],
            metalnessMap: textures_loader[1],
            metalness:1,
            normalMap:textures_loader[2],
            roughnessMap:textures_loader[3],
            roughness:1,
            emissiveMap:(map_list[5])?textures_loader[5]:null,
            alphaMap:(map_list[4])?textures_loader[4]:null,
            transparent:(!!(map_list[4]) || (materialInfo.transmission && parseFloat(materialInfo.transmission) !== 0)),
            side:(!!(map_list[4]) || (materialInfo.transmission && parseFloat(materialInfo.transmission) !== 0))?THREE.DoubleSide:THREE.FrontSide,
            sheen:(materialInfo.sheen)?materialInfo.sheen:0,
            sheenRoughness:(materialInfo.sheenRoughness)?materialInfo.sheenRoughness:1,
            sheenColor:(materialInfo.sheenColor)?materialInfo.sheenColor:0x000000,
            transmission : (materialInfo.transmission)?materialInfo.transmission:0,
            ior : (materialInfo.ior)?materialInfo.ior:1.5,
            reflectivity : (materialInfo.reflectivity)?materialInfo.reflectivity:0.5,
            thickness : (materialInfo.thickness)?materialInfo.thickness:0,
            envMapIntensity : (materialInfo.envMapIntensity)?materialInfo.envMapIntensity:1,
            clearCoat : (materialInfo.clearCoat)?materialInfo.clearCoat:0,
            clearCoatRoughness : (materialInfo.clearCoatRoughness)?materialInfo.clearCoatRoughness:0,
            emissive:(materialInfo.emissive)?materialInfo.emissive:0x000000,
        });

        console.log()

        return mat;
    }
}