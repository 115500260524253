import {EventEmitter} from "events";
import * as THREE from "three";
import Vue3D from "../Vue3D";

export default class Vue3DConfiguratorShadow extends EventEmitter {

    vue3d = null;
    folder = null;

    saveTimeout = null;

    api = {
        dropShadowSourceX:0,
        dropShadowSourceZ:0,
        dropShadowOpacity:0,
        dropShadowRadius:0,
        aoShadowBlur:0,
        aoShadowOpacity:0
    }

    constructor(_configurator)
    {
        super();

        let me = this;
        me.vue3d = _configurator.vue3d;

        me.folder = _configurator.gui.addFolder( 'Shadow');

        me.api.dropShadowSourceX = parseFloat(me.vue3d.dropShadow.shadowLight.position.x);
        me.api.dropShadowSourceZ = parseFloat(me.vue3d.dropShadow.shadowLight.position.z);
        me.api.dropShadowOpacity = parseFloat(me.vue3d.dropShadow.material.opacity);
        me.api.dropShadowRadius = parseFloat(me.vue3d.dropShadow.shadowLight.shadow.radius);

        me.api.aoShadowBlur = parseFloat(me.vue3d.aoShadow.shadow.blur);
        me.api.aoShadowOpacity = parseFloat(me.vue3d.aoShadow.shadow.opacity);

        me.dropShadow();
        me.aoShadow();

        me.folder.close();
    }

    dropShadow()
    {
        let me = this;

        me.folder.add(me.api, 'dropShadowSourceX', -3, 3, .01).onChange((value)=>{
            me.vue3d.dropShadow.shadowLight.position.x = value;

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
        me.folder.add(me.api, 'dropShadowSourceZ', -3, 3, 0.01).onChange((value)=>{
            me.vue3d.dropShadow.shadowLight.position.z = value;

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
        me.folder.add(me.api, 'dropShadowOpacity', 0, 1, 0.01).onChange((value)=>{
            me.vue3d.dropShadow.material.opacity = value;

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
        me.folder.add(me.api, 'dropShadowRadius', 0, 20, 0.01).onChange((value)=>{
            me.vue3d.dropShadow.shadowLight.shadow.radius = value;

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
    }

    aoShadow()
    {
        let me = this;

        me.folder.add(me.api, 'aoShadowBlur', 0, 1, 0.01).onChange((value)=>{
            me.vue3d.aoShadow.shadow.blur = value;
            me.vue3d.aoShadow.render();

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });

        me.folder.add(me.api, 'aoShadowOpacity', 0, 1, 0.01).onChange((value)=>{
            me.vue3d.aoShadow.plane.material.opacity = value;

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
    }

    save(){

        let me = this;

        let data = {
            drop_shadow_source_x:me.api.dropShadowSourceX,
            drop_shadow_source_z:me.api.dropShadowSourceZ,
            drop_shadow_opacity:me.api.dropShadowOpacity,
            drop_shadow_radius:me.api.dropShadowRadius,
            ao_shadow_blur:me.api.aoShadowBlur,
            ao_shadow_opacity:me.api.aoShadowOpacity
        }

        me.emit(Vue3D.CONFIGURATOR_UPDATE_CONFIG, data);
    }

}