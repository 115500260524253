import {EventEmitter} from "events";
import * as THREE from 'three';
import Vue3D from "../Vue3D";

export default class Vue3DConfiguratorLight extends EventEmitter {

    vue3d = null;
    folder = null;

    saveTimeout = null;

    api = {
        addLight:this.addLight.bind(this),
        removeLight:this.deleteLight.bind(this),
        light:"",
        color:"",
        intensity:"",
        x:0,
        y:0,
        z:0,
        width:"",
        height:""
    }

    lightSet = [];
    settings = [];
    lightSelected = null;

    static DEFAUT_LIGHT = {color:"#ffffff", intensity:1, x:0, y:1, z:0, width:0.5, height:0.5};

    constructor(_configurator)
    {
        super();

        let me = this;
        me.vue3d = _configurator.vue3d;

        me.folder = _configurator.gui.addFolder( 'Lights');
        me.folder.close();

        me.folder.add( this.api, 'addLight' );
        me.folder.add( this.api, 'removeLight' );

        me.lightSet = me.vue3d.lightSetup.lights;

        me.listLight();
    }

    listLight()
    {
        let me = this;
        if(me.settings) {
            me.settings.forEach((setting)=>{
                if(setting) setting.destroy();
            })
            me.settings = [];
        }

        let lightSelector = me.folder.add( me.api, 'light').options( me.lightSet.map((a) => a.name) ).onChange((name)=>{

            me.lightSelected = me.lightSet.find(obj => obj.name === name);

            me.api.color = me.lightSelected.color.getHexString();
            me.api.intensity = me.lightSelected.intensity;
            me.api.x = me.lightSelected.position.x;
            me.api.y = me.lightSelected.position.y;
            me.api.z = me.lightSelected.position.z;
            me.api.width = me.lightSelected.width;
            me.api.height = me.lightSelected.height;

            me.listLight();
        });
        me.settings.push(lightSelector);

        if(!me.lightSelected) return;

        let name = me.folder.add(me.api, 'light').onFinishChange((value)=>{
            if(me.lightSelected) {
                me.api.light = me.lightSelected.name = value;
                me.listLight();
                me.save();
            }
        });
        me.settings.push(name);

        let color = me.folder.addColor(me.api, 'color').onChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.color.set(value);
            }
        });
        color.onFinishChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.color.set(value);
                me.save();
            }
        });

        me.settings.push(color);

        let intensity = me.folder.add(me.api, 'intensity', 0, 5, 0.01).onChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.intensity = value;
            }
        });
        intensity.onFinishChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.intensity = value;
                me.save();
            }
        });
        me.settings.push(intensity);

        let x = me.folder.add(me.api, 'x', -5, 5, 0.01).onChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.position.x = value;
                me.lightSelected.lookAt( 0, 0, 0 );
            }
        });
        x.onFinishChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.position.x = value;
                me.lightSelected.lookAt( 0, 0, 0 );
                me.save();
            }
        });
        me.settings.push(x);

        let y = me.folder.add(me.api, 'y', -5, 5, 0.01).onChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.position.y = value;
                me.lightSelected.lookAt( 0, 0, 0 );
            }
        });
        y.onFinishChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.position.y = value;
                me.lightSelected.lookAt( 0, 0, 0 );
                me.save();
            }
        });
        me.settings.push(y);

        let z = me.folder.add(me.api, 'z', -5, 5, 0.01).onChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.position.z = value;
                me.lightSelected.lookAt( 0, 0, 0 );
            }
        });
        z.onFinishChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.position.z = value;
                me.lightSelected.lookAt( 0, 0, 0 );
                me.save();
            }
        });
        me.settings.push(z);

        let width = me.folder.add(me.api, 'width', 0, 5, 0.01).onChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.width = value;
            }
        });
        width.onFinishChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.width = value;
                me.save();
            }
        });
        me.settings.push(width);

        let height = me.folder.add(me.api, 'height', 0, 5, 0.01).onChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.height = value;
            }
        });
        height.onFinishChange((value)=>{
            if(me.lightSelected) {
                me.lightSelected.height = value;
                me.save();
            }
        });
        me.settings.push(height);

    }

    addLight()
    {
        const light = new THREE.RectAreaLight(
            Vue3DConfiguratorLight.DEFAUT_LIGHT.color,
            Vue3DConfiguratorLight.DEFAUT_LIGHT.intensity,
            Vue3DConfiguratorLight.DEFAUT_LIGHT.width,
            Vue3DConfiguratorLight.DEFAUT_LIGHT.height
        );
        light.position.set(
            Vue3DConfiguratorLight.DEFAUT_LIGHT.x,
            Vue3DConfiguratorLight.DEFAUT_LIGHT.y,
            Vue3DConfiguratorLight.DEFAUT_LIGHT.z
        );
        light.lookAt( 0, 0, 0 );
        this.api.light = light.name = "light-"+Math.round(Math.random()*1000);

        this.lightSet.push(light);

        this.lightSelected = this.lightSet.find(obj => obj.name === light.name);
        this.api.color = Vue3DConfiguratorLight.DEFAUT_LIGHT.color;
        this.api.intensity = Vue3DConfiguratorLight.DEFAUT_LIGHT.intensity;
        this.api.x = Vue3DConfiguratorLight.DEFAUT_LIGHT.x;
        this.api.y = Vue3DConfiguratorLight.DEFAUT_LIGHT.y;
        this.api.z = Vue3DConfiguratorLight.DEFAUT_LIGHT.z;
        this.api.width = Vue3DConfiguratorLight.DEFAUT_LIGHT.width;
        this.api.height = Vue3DConfiguratorLight.DEFAUT_LIGHT.height;

        this.vue3d.scene.add( light );

        this.listLight();
        this.save();
    }

    deleteLight()
    {
        let me = this;
        if(me.lightSelected)
        {
            me.vue3d.scene.remove(me.lightSelected);
            me.lightSet.splice(me.lightSet.indexOf(me.lightSelected), 1);
            me.api.light = "";
            me.lightSelected = null;
            me.listLight();
            me.save();
        }
    }

    save() {
        let me = this;
        let data = {};
        data.light_set = {};
        let parser = [];
        me.lightSet.forEach((l)=>{
           let toSave = {};
           toSave.name = l.name;
           toSave.intensity = l.intensity;
           toSave.color = l.color.getHexString();
           toSave.x = l.position.x;
           toSave.y = l.position.y;
           toSave.z = l.position.z;
           toSave.width = l.width;
           toSave.height = l.height;
           parser.push(toSave);
        });

        data.light_set = JSON.stringify(parser);
        me.emit(Vue3D.CONFIGURATOR_UPDATE_CONFIG, data);
    }

}