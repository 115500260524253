/**
 * Created by Matthieu on 04/02/2021.
 */
import * as THREE from 'three';

var EventEmitter = require('events').EventEmitter;

import Vue3DRenderer from "./renderer/Vue3DRenderer";
import Vue3DDropShadow from "./shadow/Vue3DDropShadow";
import Vue3DAOShadow from "./shadow/Vue3DAOShadow";

import Vue3DControls from "./controls/Vue3DControls";
import Vue3DLabelRenderer from "./renderer/Vue3DLabelRenderer";
import Vue3DConfigurator from "./configurator/Vue3DConfigurator";
import Vue3DCaptureMode from "./renderer/Vue3DCaptureMode";
import Vue3DModelManager from "./model/Vue3DModelManager";
import Vue3DMaterialManager from "./material/Vue3DMaterialManager";

import {GLTFExporter} from "three/examples/jsm/exporters/GLTFExporter";
import Vue3DLightSetup from "./lights/Vue3DLightSetup";

export default class Vue3D extends EventEmitter
{
    /**static renvoyées par les emiter*/
    /**
     * la scene est prête et la HDR chargée
     * @type {string}
     */
    static READY = "READY";
    /**
     * le model et ses textures sont chargés ou les textures demandées sont chargées
     * @type {string}
     */
    static LOAD_COMPLETE = "LOAD_COMPLETE";
    /**
     * le configurateur envoi un event avec les paramètres necessaire pour demander une mise à jour de la BDD
     * @type {string}
     */
    static CONFIGURATOR_UPDATE_MATERIAL = "CONFIGURATOR_UPDATE_MATERIAL";
    static CONFIGURATOR_UPDATE_CONFIG = "CONFIGURATOR_UPDATE_CONFIG";
    /**
     * le nom du container dans le dom
     * @type {string}
     */
    domContainer = null;
    /**
     * Chemin vers la racine du projet
     * @type {string}
     */
    httpRootPath = "";
    /**
     * Scene 3D
     * @type {THREE.Scene}
     */
    scene = null;
    /**
     * Camera 3D
     * @type {THREE.PerspectiveCamera}
     */
    camera = null;
    /**
     * renderer 3D
     * @type {Vue3DRenderer}
     */
    renderer = null;
    /**
     * renderer pour les labels
     * @type {Vue3DLabelRenderer}
     */
    labelRenderer = null;
    /**
     * controles de la camera
     * @type {Vue3DControls}
     */
    controls = null;
    /**
     * Ombre ortho projetée au sol
     * @type {Vue3DDropShadow}
     */
    dropShadow = null;
    /**
     * Ombre ortho projetée au sol
     * @type {Vue3DAOShadow}
     */
    aoShadow = null;
    /**
     * Le setup de light
     * @type {null}
     */
    lightSetup = null;
    /**
     * Le modele 3D
     */
    model = null;
    /**
     * Le root du modele 3D, permet de clean la scene plus facilement
     * @type {THREE.Group}
     */
    modelRoot = null;
    /**
     * Le configurateur 3D
     * @type {Vue3DConfigurator}
     */
    configurator = null;

    constructor(_container = "") {

        super();

        let me = this;

        this.envSettings = JSON.parse(LayoutVars.envSettings);

        this.httpRootPath = this.envSettings.root_path;

        this.domContainer = document.querySelector(_container);
    }

    initScene()
    {
        this.domContainer.classList.add("loading");

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera( 30, this.domContainer.clientWidth / this.domContainer.clientHeight, 0.05, 30 );

        this.renderer = new Vue3DRenderer(this);
        this.renderer.loadHDR(this.sceneReady);
        //this.renderer.initSSAO(this.scene, this.camera);
        //this.labelRenderer = new Vue3DLabelRenderer(this);
        this.controls = new Vue3DControls(this);

        this.lightSetup = new Vue3DLightSetup(this);

        this.dropShadow = new Vue3DDropShadow(this);
        this.aoShadow = new Vue3DAOShadow(this);

        this.startRenderer();
        window.addEventListener( 'resize', this.onWindowResize.bind(this), false );
    }

    initConfigurator()
    {
        let me = this;

        this.configurator = new Vue3DConfigurator(this);

        this.configurator.on(Vue3D.CONFIGURATOR_UPDATE_MATERIAL, (data)=>{
            me.emit(Vue3D.CONFIGURATOR_UPDATE_MATERIAL, data);
        });

        this.configurator.on(Vue3D.CONFIGURATOR_UPDATE_CONFIG, (data)=>{
            me.emit(Vue3D.CONFIGURATOR_UPDATE_CONFIG, data);
        });
    }

    sceneReady(me)
    {
        me.domContainer.classList.remove("loading");
        me.emit(Vue3D.READY);
    }

    startRenderer()
    {
        this.render();
    }

    stopRenderer()
    {
        cancelAnimationFrame(this.rendererRequestFrameID);
    }

    resetCamera()
    {
        if(this.model) this.controls.resetCameraToObject(this.model);
    }


    initPreviewMode()
    {
        Vue3DCaptureMode.set(this);
    }

    getImageFromRenderer()
    {
        let me = this;
        let imgData;
        let pixelRatio = window.devicePixelRatio;

        me.renderer.setSize( 1024/pixelRatio, 1024/pixelRatio );

        if(this.camera)
        {
            this.camera.aspect = 1;
            this.camera.updateProjectionMatrix();
        }
        me.renderer.update();

        try {
            let strMime = "image/png";

            imgData = me.renderer.domElement.toDataURL(strMime);

            if(this.camera)
            {
                this.camera.aspect = this.domContainer.clientWidth / this.domContainer.clientHeight;
                this.camera.updateProjectionMatrix();
            }

            if(this.renderer) this.renderer.resize();

            return imgData;

        } catch (e) {

            if(this.camera)
            {
                this.camera.aspect = this.domContainer.clientWidth / this.domContainer.clientHeight;
                this.camera.updateProjectionMatrix();
            }

            if(this.renderer) this.renderer.resize();

            return null;
        }
    }


    loadModel(modelFile, options = null, cbSuccess = null)
    {
        Vue3DModelManager.load(this, modelFile, options, cbSuccess);
    }

    setMaterials(materialList)
    {
        Vue3DMaterialManager.setMaterials(this, materialList);
    }

    render()
    {
        if(this.configurator && this.configurator.stats) this.configurator.stats.begin();

        if(this.controls) this.controls.update();
        this.renderer.update();
        if(this.labelRenderer) this.labelRenderer.render( this.scene, this.camera );

        this.rendererRequestFrameID = requestAnimationFrame( this.render.bind(this) );

        if(this.configurator && this.configurator.stats) this.configurator.stats.end();
    }

    onWindowResize()
    {
        if(this.camera)
        {
            this.camera.aspect = this.domContainer.clientWidth / this.domContainer.clientHeight;
            this.camera.updateProjectionMatrix();
        }

        if(this.labelRenderer) this.labelRenderer.setSize( this.domContainer.clientWidth, this.domContainer.clientHeight );
        if(this.renderer) this.renderer.resize();

        this.controls.resetCameraToObject(this.model);
    }
}