import Vue3D from "./../Vue3D";

export default class Vue3DIframeWrapper
{

    static EVENT = {
        LOAD_COMPLETE:"LOAD_COMPLETE",
        MODEL_DATA:"MODEL_DATA",
        RENDER_TO_BASE64_DATA:"RENDER_TO_BASE64_DATA"
    }

    static ACTION = {
        GET_MODEL_DATA:"GET_MODEL_DATA",
        CHANGE_MATERIAL:"CHANGE_MATERIAL",
        START_RENDERER:"START_RENDERER",
        PAUSE_RENDERER:"PAUSE_RENDERER",
        RESET_CAMERA:"RESET_CAMERA",
        RENDER_TO_BASE64:"RENDER_TO_BASE64"
    }

    static model_nav = {}

    static init(site, container)
    {
        site.vue3d = new Vue3D(container, LayoutVars.rootPath);
        site.vue3d.initScene();

        /*site.vue3d.on(Vue3D.READY, ()=>{
            parent.postMessage({event:Vue3DIframeWrapper.EVENT.READY}, "*");
        });*/

        site.vue3d.on(Vue3D.LOAD_COMPLETE, ()=>{

            let data = {};
            data.model = LayoutVars.model;
            data.material_list = JSON.parse(LayoutVars.options).materials;
            api.Api3dvue.getProductURL( JSON.stringify(data),(msg, html, json)=>{
                delete data.model;
                data.product_id = json.url;
                parent.postMessage({event:Vue3DIframeWrapper.EVENT.LOAD_COMPLETE, data:JSON.stringify(data)}, "*");
            }, null);
        });


        if(LayoutVars.model)
        {
            site.loadModel(LayoutVars.model, LayoutVars.options?JSON.parse(LayoutVars.options):null);
        }

        window.addEventListener("message", function(e) {

            switch (e.data.action)
            {
                case Vue3DIframeWrapper.ACTION.GET_MODEL_DATA:
                    api.Api3dvue.getModelData( LayoutVars.model,(msg, html, json)=>{
                        parent.postMessage({event:Vue3DIframeWrapper.EVENT.MODEL_DATA, data:JSON.stringify(json)}, "*");
                    }, null);

                    //parent.postMessage({event:Vue3DIframeWrapper.EVENT.MODEL_DATA, data:LayoutVars.model_data}, "*");
                    break;
                case Vue3DIframeWrapper.ACTION.CHANGE_MATERIAL:
                    site.loadMaterials(e.data.materials);
                    break;
                case Vue3DIframeWrapper.ACTION.START_RENDERER:
                    site.vue3d.startRenderer();
                    break;
                case Vue3DIframeWrapper.ACTION.PAUSE_RENDERER:
                    site.vue3d.stopRenderer();
                    break;
                case Vue3DIframeWrapper.ACTION.RESET_CAMERA:
                    site.vue3d.resetCamera();
                    break;
                case Vue3DIframeWrapper.ACTION.RENDER_TO_BASE64:
                    let base64 = site.vue3d.getImageFromRenderer();
                    api.Api3dvue.getModelData( LayoutVars.model,(msg, html, json)=>{
                        parent.postMessage({event:Vue3DIframeWrapper.EVENT.RENDER_TO_BASE64_DATA, data:base64}, "*");
                    }, null);
                    break;

            }
        });
    }
}