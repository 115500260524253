import * as THREE from "three";
import Vue3D from "../Vue3D";
import {EventEmitter} from "events";

export default class Vue3DConfiguratorMaterial extends EventEmitter {

    vue3d = null;
    configurator = null;
    folder = null;

    materialCtrl = null;
    sheenCtrl = null;
    sheenRoughnessCtrl = null;
    sheenColorCtrl = null;
    transmissionCtrl = null;
    iorCtrl = null;
    reflectivityCtrl = null;
    thicknessCtrl = null;
    envMapIntensityCtrl = null;
    clearCoatCtrl = null;
    clearCoatRoughnessCtrl = null;
    emissiveCtrl = null;
    repeatCtrl = null;
    saveTimeout = null;

    api = {
        mesh:"",
        material:"",
        sheen:0,
        sheenRoughness:1,
        sheenColor:"#000000",
        transmission:0,
        ior:1.5,
        reflectivity:0.5,
        thickness:0,
        envMapIntensity:1,
        clearCoat:0,
        clearCoatRoughness:0,
        emissive:"#000000",
        repeat:1
    }

    selectedMeshKey = "";
    selectedMaterialKey = "";


    constructor(_configurator)
    {
        super();

        let me = this;
        me.vue3d = _configurator.vue3d;
        me.configurator = _configurator;
    }

    listMesh()
    {
        let me = this;
        me.api.mesh = "";
        me.selectedMeshKey = "";

        let mesh = [];
        me.vue3d.model.traverse(function (child) {
            if (child.isMesh) {
                let pattern = "mesh_";
                if(!child.name.includes(pattern)) return;
                let identifier = pattern+child.name.slice(pattern.length, pattern.length+3);

                if(mesh.includes(identifier)) return;
                mesh.push(identifier);
            }
        });

        mesh.sort();
        me.api.material = "";

        mesh.forEach((value, index)=>
        {
            mesh[index] = LayoutVars.meshs[mesh[index]];
        });

        me.api.mesh = mesh[0];
        me.selectedMeshKey = Object.entries(LayoutVars.meshs).find(([key, value]) => value === mesh[0])?.[0];

        me.folder = me.configurator.gui.addFolder( 'Materials' );
        me.folder.add( this.api, 'mesh' ).options( mesh ).onChange( function (selection) {
            me.api.material = "";
            if(me.materialCtrl) me.materialCtrl.updateDisplay();

            me.selectedMeshKey = Object.entries(LayoutVars.meshs).find(([key, value]) => value === selection)?.[0];

            me.vue3d.model.traverse(function (child) {
                if(child.isMesh) {
                    if(child.name.includes(me.selectedMeshKey))
                    {
                        if(me.materialCtrl) {
                            let human_name = Object.entries(LayoutVars.materials).find(([key, value]) => value.name === child.material.name)?.[1].human_name;
                            me.selectedMaterialKey = child.material.name;
                            me.api.material = (human_name)?human_name:"";
                            me.api.sheen = (child.material.sheen)?child.material.sheen:0;
                            me.api.sheenRoughness = (child.material.sheenRoughness)?child.material.sheenRoughness:1;
                            me.api.sheenColor = (child.material.sheenColor)?child.material.sheenColor.getHexString():"#000000";
                            me.api.transmission = (child.material.transmission)?child.material.transmission:0;
                            me.api.ior = (child.material.ior)?child.material.ior:1.5;
                            me.api.reflectivity = (child.material.reflectivity)?child.material.reflectivity:0.5;
                            me.api.thickness = (child.material.thickness)?child.material.thickness:0;
                            me.api.envMapIntensity = (child.material.envMapIntensity)?child.material.envMapIntensity:1;
                            me.api.clearCoat = (child.material.clearCoat)?child.material.clearCoat:0;
                            me.api.clearCoatRoughness = (child.material.clearCoatRoughness)?child.material.clearCoatRoughness:0;
                            me.api.emissive = (child.material.emissive)?child.material.emissive.getHexString():"#000000";
                            me.api.repeat = (child.material.map)?child.material.map.repeat.x:1;


                            if(me.materialCtrl) me.materialCtrl.updateDisplay();
                            if(me.sheenCtrl) me.sheenCtrl.updateDisplay();
                            if(me.sheenRoughnessCtrl) me.sheenRoughnessCtrl.updateDisplay();
                            if(me.sheenColorCtrl) me.sheenColorCtrl.updateDisplay();
                            if(me.transmissionCtrl) me.transmissionCtrl.updateDisplay();
                            if(me.iorCtrl) me.iorCtrl.updateDisplay();
                            if(me.reflectivityCtrl) me.reflectivityCtrl.updateDisplay();
                            if(me.thicknessCtrl) me.thicknessCtrl.updateDisplay();
                            if(me.envMapIntensityCtrl) me.envMapIntensityCtrl.updateDisplay();
                            if(me.clearCoatCtrl) me.clearCoatCtrl.updateDisplay();
                            if(me.clearCoatRoughnessCtrl) me.clearCoatRoughnessCtrl.updateDisplay();
                            if(me.emissiveCtrl) me.emissiveCtrl.updateDisplay();
                            if(me.repeatCtrl) me.repeatCtrl.updateDisplay();
                        }
                    }
                }
            });
        });

        me.listMaterial();
        me.sheenCtrl = me.simpleSlider("sheen");
        me.sheenRoughnessCtrl = me.simpleSlider("sheenRoughness");
        me.sheenColorCtrl = me.sheenColor();
        me.transmissionCtrl = me.simpleSlider("transmission");
        me.iorCtrl = me.simpleSlider('ior', 0, 2.333, 0.01);
        me.reflectivityCtrl = me.simpleSlider('reflectivity');
        me.thicknessCtrl = me.simpleSlider('thickness', 0, 5);
        me.envMapIntensityCtrl = me.simpleSlider('envMapIntensity', 0 , 2);
        me.clearCoatCtrl = me.simpleSlider('clearCoat');
        me.clearCoatRoughnessCtrl = me.simpleSlider('clearCoatRoughness');
        me.emissiveCtrl = me.emissiveColor();
        me.repeatCtrl = me.simpleSlider('repeat', 0.1, 25, 0.1);
    }


    listMaterial()
    {
        let me = this;


        me.materialCtrl = me.folder.add( me.api, 'material' ).options( LayoutVars.materials.map(a => a.human_name) );

        me.materialCtrl.onChange( function (selection) {

        //.find(objet => objet.nom === valeurRecherchee)

            me.selectedMaterialKey = Object.entries(LayoutVars.materials).find(([key, value]) => value.human_name === selection)?.[1].name;

            if(me.api.mesh !== "" && me.api.material !== "")
            {
                let key = me.selectedMeshKey;
                const materialInfo = LayoutVars.materials.find(({ name }) => name === me.selectedMaterialKey);
                let data = {[key] : materialInfo};

                me.api.sheen = (data[key].sheen)?data[key].sheen:0;
                me.api.sheenRoughness = (data[key].sheenRoughness)?data[key].sheenRoughness:1;
                me.api.sheenColor = (data[key].sheenColor)?data[key].sheenColor:"#000000";
                me.api.transmission = (data[key].transmission)?data[key].transmission:0;
                me.api.ior = (data[key].ior)?data[key].ior:1.5;
                me.api.reflectivity = (data[key].reflectivity)?data[key].reflectivity:0.5;
                me.api.thickness = (data[key].thickness)?data[key].thickness:0;
                me.api.envMapIntensity = (data[key].envMapIntensity)?data[key].envMapIntensity:1;
                me.api.clearCoat = (data[key].clearCoat)?data[key].clearCoat:0;
                me.api.clearCoatRoughness = (data[key].clearCoatRoughness)?data[key].clearCoatRoughness:0;
                me.api.emissive = (data[key].emissive)?data[key].emissive:"#000000";
                me.api.repeat = (data[key].repeat)?data[key].repeat:1;

                if(me.sheenCtrl) me.sheenCtrl.updateDisplay();
                if(me.sheenRoughnessCtrl) me.sheenRoughnessCtrl.updateDisplay();
                if(me.sheenColorCtrl) me.sheenColorCtrl.updateDisplay();
                if(me.transmissionCtrl) me.transmissionCtrl.updateDisplay();
                if(me.iorCtrl) me.iorCtrl.updateDisplay();
                if(me.reflectivityCtrl) me.reflectivityCtrl.updateDisplay();
                if(me.thicknessCtrl) me.thicknessCtrl.updateDisplay();
                if(me.envMapIntensityCtrl) me.envMapIntensityCtrl.updateDisplay();
                if(me.clearCoatCtrl) me.clearCoatCtrl.updateDisplay();
                if(me.clearCoatRoughnessCtrl) me.clearCoatRoughnessCtrl.updateDisplay();
                if(me.emissiveCtrl) me.emissiveCtrl.updateDisplay();
                if(me.repeatCtrl) me.repeatCtrl.updateDisplay();

                me.vue3d.setMaterials(data);

                //me.configurator.modelConfigurator.updateURLFromScene();
            }
        });
    }

    destroy()
    {
        if(this.folder) this.folder.destroy();
    }

    save()
    {
        let me = this;

        if(me.api.mesh !== "" && me.api.material !== "")
        {
            let data = {
                materialName : me.selectedMaterialKey,
                sheen : me.api.sheen,
                sheenRoughness : me.api.sheenRoughness,
                sheenColor : me.api.sheenColor,
                transmission : me.api.transmission,
                ior : me.api.ior,
                reflectivity : me.api.reflectivity,
                thickness : me.api.thickness,
                envMapIntensity : me.api.envMapIntensity,
                clearCoat : me.api.clearCoat,
                clearCoatRoughness : me.api.clearCoatRoughness,
                emissive : me.api.emissive,
                repeat:me.api.repeat
            }

            me.emit(Vue3D.CONFIGURATOR_UPDATE_MATERIAL, data);

            const materialInfo = LayoutVars.materials.find(({ name }) => name === me.selectedMaterialKey);
            materialInfo.sheen = me.api.sheen;
            materialInfo.sheenRoughness = me.api.sheenRoughness;
            materialInfo.sheenColor = me.api.sheenColor;
            materialInfo.transmission = me.api.transmission;
            materialInfo.ior = me.api.ior;
            materialInfo.reflectivity = me.api.reflectivity;
            materialInfo.thickness = me.api.thickness;
            materialInfo.envMapIntensity = me.api.envMapIntensity;
            materialInfo.clearCoat = me.api.clearCoat;
            materialInfo.clearCoatRoughness = me.api.clearCoatRoughness;
            materialInfo.emissive = me.api.emissive;
            materialInfo.repeat = me.api.repeat;

        }
    }


    simpleSlider(parameter, min = 0, max = 1, step = 0.01)
    {
        let me = this;

        return me.folder.add(me.api, parameter, min, max, step).onChange((value)=>{
            me.vue3d.model.traverse(function (child) {
                if (child.isMesh && child.name.includes(me.selectedMeshKey)) {
                    child.material[parameter] = value;
                    if(parameter === "transmission")
                    {
                        child.material.transparent = true;
                        child.material.side = (!!(child.material.alphaMap) || (child.material.transmission && parseFloat(child.material.transmission) !== 0))?THREE.DoubleSide:THREE.FrontSide;
                    } else if (parameter === "repeat"){
                        if(child.material.map) child.material.map.repeat.set(value, value);
                        if(child.material.metalnessMap) child.material.metalnessMap.repeat.set(value, value);
                        if(child.material.normalMap) child.material.normalMap.repeat.set(value, value);
                        if(child.material.roughnessMap) child.material.roughnessMap.repeat.set(value, value);
                        if(child.material.alphaMap) child.material.alphaMap.repeat.set(value, value);
                        if(child.material.emissiveMap) child.material.emissiveMap.repeat.set(value, value);
                    }

                    child.material.needsUpdate = true;
                }
            });

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
    }

    sheenColor()
    {
        let me = this;

        return me.folder.addColor(me.api, 'sheenColor').onChange((value)=>{
            me.vue3d.model.traverse(function (child) {
                if (child.isMesh && child.name.includes(me.selectedMeshKey)) {
                    if(child.material.sheenColor) child.material.sheenColor.set(value);
                    child.material.needsUpdate = true;
                }
            });

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
    }

    emissiveColor()
    {
        let me = this;

        return me.folder.addColor(me.api, 'emissive').onChange((value)=>{
            me.vue3d.model.traverse(function (child) {
                if (child.isMesh && child.name.includes(me.selectedMeshKey)) {
                    if(child.material.emissive) child.material.emissive.set(value);
                    child.material.needsUpdate = true;
                }
            });

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
    }
}