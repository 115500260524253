import * as THREE from 'three';

export default class Vue3DDropShadow {

    shadowLight = null;
    material = null;
    vue3d = null;

    constructor(_vue3d) {

        let me = this;
        me.vue3d = _vue3d;

        let px = me.vue3d.envSettings.drop_shadow_source_x;
        let pz = me.vue3d.envSettings.drop_shadow_source_z;
        let opacity = me.vue3d.envSettings.drop_shadow_opacity;
        let radius = me.vue3d.envSettings.drop_shadow_radius;

        this.shadowLight = new THREE.DirectionalLight( 0xffffff, 0 );
        this.shadowLight.position.set( px, 3, pz );
        this.shadowLight.lookAt(0, 0, 0);
        this.shadowLight.shadow.mapSize.width = 2048;
        this.shadowLight.shadow.mapSize.height = 2048;
        this.shadowLight.shadow.radius = radius;
        this.shadowLight.shadow.bias = 0.75;
        this.shadowLight.shadow.blurSamples = 10;
        this.shadowLight.castShadow = true;
        this.shadowLight.shadow.camera.near = 0.5;
        this.shadowLight.shadow.camera.far = 40;
        let d = 1;
        this.shadowLight.shadow.camera.left = d;
        this.shadowLight.shadow.camera.right = -d;
        this.shadowLight.shadow.camera.top = d;
        this.shadowLight.shadow.camera.bottom = -d;
        me.vue3d.scene.add( this.shadowLight );

        const geometry = new THREE.PlaneGeometry( 20, 20 );
        geometry.rotateX( - Math.PI / 2 );

        this.material = new THREE.ShadowMaterial({
            color: 0x000000,
            depthWrite: false,
            transparent: true,
        });
        this.material.opacity = opacity;

        const plane = new THREE.Mesh( geometry, this.material );
        plane.receiveShadow = true;
        me.vue3d.scene.add( plane );
    }

}