import * as THREE from "three";
import {HorizontalBlurShader} from "three/examples/jsm/shaders/HorizontalBlurShader";
import {VerticalBlurShader} from "three/examples/jsm/shaders/VerticalBlurShader";

export default class Vue3DAOShadow {

    vue3d = null;
    plane = null;
    blurPlane = null;
    renderTarget = null;
    renderTargetBlur = null;
    shadowCamera = null;
    depthMaterial = null;
    horizontalBlurMaterial = null;
    verticalBlurMaterial = null;

    constructor(_vue3d) {

        this.vue3d = _vue3d;

        this.shadow =  {
            blur: this.vue3d.envSettings.ao_shadow_blur,
            darkness: 10,
            opacity: this.vue3d.envSettings.ao_shadow_opacity
        }

        const PLANE_WIDTH = 4;
        const PLANE_HEIGHT = 4;

        let shadowGroup = new THREE.Group();
        this.vue3d.scene.add( shadowGroup );

        this.renderTarget = new THREE.WebGLRenderTarget( 2048*2, 2048*2 );
        this.renderTarget.texture.generateMipmaps = false;

        // the render target that we will use to blur the first render target
        this.renderTargetBlur = new THREE.WebGLRenderTarget( 2048*2, 2048*2 );
        this.renderTargetBlur.texture.generateMipmaps = false;

        // make a plane and make it face up
        const planeGeometry = new THREE.PlaneGeometry( PLANE_WIDTH, PLANE_HEIGHT ).rotateX( Math.PI / 2 );
        const planeMaterial = new THREE.MeshBasicMaterial( {
            map: this.renderTarget.texture,
            opacity: this.shadow.opacity,
            transparent: true,
            depthWrite: false,
        } );
        this.plane = new THREE.Mesh( planeGeometry, planeMaterial );
        // make sure it's rendered after the fillPlane
        this.plane.renderOrder = 1;
        this.plane.scale.y = - 1;
        shadowGroup.add( this.plane );

        this.blurPlane = new THREE.Mesh( planeGeometry );
        this.blurPlane.visible = false;
        shadowGroup.add( this.blurPlane );

        // the plane with the color of the ground
        const fillPlaneMaterial = new THREE.MeshBasicMaterial( {
            color: "#ffffff",
            opacity: 0,
            transparent: true,
            depthWrite: false,
        } );
        let fillPlane = new THREE.Mesh( planeGeometry, fillPlaneMaterial );
        fillPlane.rotateX( Math.PI );
        shadowGroup.add( fillPlane );

        this.shadowCamera = new THREE.OrthographicCamera( - PLANE_WIDTH / 2, PLANE_WIDTH / 2, PLANE_HEIGHT / 2, - PLANE_HEIGHT / 2, -0.01, 0.1 );
        this.shadowCamera.rotation.x = Math.PI / 2; // get the camera to look up
        shadowGroup.add( this.shadowCamera );

        let me = this;
        // like MeshDepthMaterial, but goes from black to transparent
        this.depthMaterial = new THREE.MeshDepthMaterial();
        this.depthMaterial.userData.darkness = { value: this.shadow.darkness };
        this.depthMaterial.onBeforeCompile = function ( shader ) {

            shader.uniforms.darkness = me.depthMaterial.userData.darkness;
            shader.fragmentShader = /* glsl */`
						uniform float darkness;
						${shader.fragmentShader.replace(
                'gl_FragColor = vec4( vec3( 1.0 - fragCoordZ ), opacity );',
                'gl_FragColor = vec4( vec3( 0.0 ), ( 1.0 - fragCoordZ ) * darkness );'
            )}
					`;

        };

        this.depthMaterial.depthTest = false;
        this.depthMaterial.depthWrite = false;

        this.horizontalBlurMaterial = new THREE.ShaderMaterial( HorizontalBlurShader );
        this.horizontalBlurMaterial.depthTest = false;

        this.verticalBlurMaterial = new THREE.ShaderMaterial( VerticalBlurShader );
        this.verticalBlurMaterial.depthTest = false;
    }

    blurShadow( amount ) {

        this.blurPlane.visible = true;

        // blur horizontally and draw in the renderTargetBlur
        this.blurPlane.material = this.horizontalBlurMaterial;
        this.blurPlane.material.uniforms.tDiffuse.value = this.renderTarget.texture;
        this.horizontalBlurMaterial.uniforms.h.value = amount * 1 / 256;

        this.vue3d.renderer.setRenderTarget( this.renderTargetBlur );
        this.vue3d.renderer.render( this.blurPlane, this.shadowCamera );

        // blur vertically and draw in the main renderTarget
        this.blurPlane.material = this.verticalBlurMaterial;
        this.blurPlane.material.uniforms.tDiffuse.value = this.renderTargetBlur.texture;
        this.verticalBlurMaterial.uniforms.v.value = amount * 1 / 256;

        this.vue3d.renderer.setRenderTarget( this.renderTarget );
        this.vue3d.renderer.render( this.blurPlane, this.shadowCamera );

        this.blurPlane.visible = false;

    }

    render()
    {
        // remove the background
        const initialBackground = this.vue3d.scene.background;
        this.vue3d.scene.background = null;

        // force the depthMaterial to everything
        this.vue3d.scene.overrideMaterial = this.depthMaterial;

        // set renderer clear alpha
        const initialClearAlpha = this.vue3d.renderer.getClearAlpha();
        this.vue3d.renderer.setClearAlpha( 0 );

        // render to the render target to get the depths
        this.vue3d.renderer.setRenderTarget( this.renderTarget );
        this.vue3d.renderer.render( this.vue3d.scene, this.shadowCamera );

        // and reset the override material
        this.vue3d.scene.overrideMaterial = null;

        this.blurShadow( this.shadow.blur );

        // a second pass to reduce the artifacts
        // (0.4 is the minimum blur amout so that the artifacts are gone)
        this.blurShadow( this.shadow.blur * 0.4 );

        // reset and render the normal scene
        this.vue3d.renderer.setRenderTarget( null );
        this.vue3d.renderer.setClearAlpha( initialClearAlpha );
        this.vue3d.scene.background = initialBackground;
    }
}