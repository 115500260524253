import Vue3D from "./../Vue3D";
import * as THREE from 'three';
import {GLTFExporter} from "three/examples/jsm/exporters/GLTFExporter";
var EventEmitter = require('events').EventEmitter;

export default class Vue3DProductGenerator extends EventEmitter
{

    static EVENT_PROGRESS = "EVENT_PROGRESS";
    static EVENT_COMPLETE = "EVENT_COMPLETE";

    constructor(data) {
        super();

        let me = this;

        const exporter = new GLTFExporter();

        data.traverse(function (child) {
            if (child.isMesh) {
                let mat = child.material;

                mat.transmission = parseFloat(mat.transmission);
                mat.thickness = parseFloat(mat.thickness);
            }
        });

        exporter.parse(
            data,
            // called when the gltf has been generated
            function ( gltf ) {

                let filename = "glb_export_" + Date.now() + ".glb";
                //let output = JSON.stringify(gltf);
                const blob = new Blob([gltf], { type: 'model/gltf-binary' });
                //let blob = new Blob([output], { type: 'application/json' });
                me.uploadBlobInChunks(blob, filename, (url)=>{
                    me.emit(Vue3DProductGenerator.EVENT_COMPLETE, url);
                });
            },
            null,
            {
                binary:true,
                maxTextureSize:1024
            }
        );
    }

    async uploadBlobInChunks(blob, fileName, cbSuccess)
    {
        let me = this;

        const CHUNK_SIZE = 1024 * 1024; // 1 MB per chunk
        const totalChunks = Math.ceil(blob.size / CHUNK_SIZE);

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const start = chunkIndex * CHUNK_SIZE;
            const end = Math.min(blob.size, start + CHUNK_SIZE);
            const chunk = blob.slice(start, end); // Slice the blob into chunks

            const formData = new FormData();
            formData.append("file", chunk);
            formData.append("chunkIndex", chunkIndex);
            formData.append("totalChunks", totalChunks);
            formData.append("fileName", fileName);

            await me.uploadChunk(formData, cbSuccess);
        }
        console.log("Upload complete!");
    }

    async uploadChunk(formData, cbComplete) {
        let url=`${LayoutVars.rootUrl}/api/3dvue/upload-file`;

        try {
            const response = await fetch(url, {
                method: "POST",
                body: formData,
            });
            const result = await response.text();
            let data = JSON.parse(result);

            console.log(data);

            if(data.json.upload_complete)
            {
                if(cbComplete) cbComplete(data.json.upload_complete);
            }


        } catch (error) {
            console.error("Error uploading chunk:", error);
        }
    }




    static download(data, cbSuccess)
    {
        const exporter = new GLTFExporter();

        data.traverse(function (child) {
            if (child.isMesh) {
                let mat = child.material;

                mat.transmission = parseFloat(mat.transmission);
                mat.thickness = parseFloat(mat.thickness);
            }
        });


        exporter.parse(
            data,
            // called when the gltf has been generated
            function ( gltf ) {

                if(cbSuccess) cbSuccess();

                const blob = new Blob([gltf], { type: 'model/gltf-binary' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = '3dvue_model_'+ Date.now() +'.glb';
                link.click();
            },
            null,
            {
                binary:true,
                maxTextureSize:1024
            }
        );

    }
}