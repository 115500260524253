import * as THREE from 'three';
import { RectAreaLightHelper }  from 'three/examples/jsm/helpers/RectAreaLightHelper.js';

export default class Vue3DLightSetup {

    vue3d = null;
    lights = [];

    constructor(_vue3D) {

        this.vue3d = _vue3D;

        if(this.vue3d.envSettings.light_set)
        {
            let lights = JSON.parse(this.vue3d.envSettings.light_set);

            lights.forEach((data)=>{
                let light = new THREE.RectAreaLight( "#"+data.color, data.intensity,  data.width, data.height );
                light.name = data.name;
                light.position.set(data.x, data.y, data.z);
                light.lookAt(0, 0, 0);

                this.vue3d.scene.add(light);
                this.lights.push(light);
            });
        }
    }

}